import React from "react"

const SpinLoader = ({
    
}) => {

    return (
        <div className="pl-loader-container">
            <span className="pl-loader"></span>
        </div>
    )
};

export default SpinLoader;
