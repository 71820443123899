import React from "react"

const Star = ({
    rate,
    isActive,
    handleStarChange
}) => {

    const onStarClick = () => {
        handleStarChange(rate)
    }

    return (
        <div className="pl-star-rate__star" onClick={onStarClick}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.7163 0.976078C9.11827 -0.325357 10.8817 -0.325361 11.2837 0.976075L12.7105 5.59551C12.8905 6.17835 13.4106 6.57292 13.9971 6.57158L18.6456 6.56097C19.9553 6.55798 20.5002 8.30917 19.439 9.11051L15.6723 11.9549C15.1971 12.3137 14.9984 12.9522 15.1809 13.5342L16.627 18.1471C17.0344 19.4467 15.6078 20.5289 14.5499 19.7228L10.7952 16.8612C10.3214 16.5002 9.67855 16.5002 9.20481 16.8612L5.45007 19.7228C4.39225 20.5289 2.96558 19.4467 3.373 18.1471L4.81913 13.5342C5.00159 12.9522 4.80292 12.3137 4.32767 11.9549L0.560992 9.11051C-0.500197 8.30917 0.0447364 6.55798 1.35436 6.56097L6.00285 6.57158C6.58936 6.57292 7.10948 6.17835 7.2895 5.59551L8.7163 0.976078Z" fill={isActive ? "#F7AC2A" : "#DCDCDC"} />
            </svg>
        </div>
    )
};

export default Star;
